import React, { useEffect, useState } from 'react'
import moment from "moment"
import fileDownload from 'js-file-download'
import AppService from 'services/AppService'
import { DatePicker, Pagination, Table, Card, Breadcrumb, Button } from 'antd'
import {  useParams } from 'react-router-dom'
import Flex from 'components/shared-components/Flex'
import { HiLocationMarker } from "react-icons/hi"
import { API_BASE_URL } from 'configs/AppConfig'


export default function GetAttendence() {

    const { id } = useParams()
    const [attendences, setAttendences] = useState([])
    const [totalAttendences, setTotalAttendences] = useState(0)
    const [loading, setLoading] = useState(false)
    const [totalhr, setTotalhr] = useState(0)
    const [pageno, setPageno] = useState(1)
    const [itemsperpage, setItemsperpage] = useState(10)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    //table
    const columns = [
        {
            title: 'Punch In',
            dataIndex: 'punchin',
            key: 'punchin',
            render: punchin =>
                <div>
                    {punchin ? <p> {moment(punchin).format("DD MMM YYYY")}, <span>{punchin && moment(punchin).format("hh:mm A")}</span></p> :
                        <div className='ml-5'> - </div>

                    }</div>

        },
        {
            title: 'Punch Out',
            dataIndex: 'punchout',
            key: 'punchout',
            render: punchout =>
                <div>
                    {punchout ? <p> {moment(punchout).format(" DD MMM YYYY")}, <span>{punchout && moment(punchout).format("hh:mm A")}</span></p> :
                        <div className='ml-5'> - </div>
                    }
                </div>

        },
        {
            title: 'Late Punch In Reason ',
            dataIndex: 'punchinnote',
            key: 'punchinnote',
            render: punchinnote => (
                <div>{punchinnote ? punchinnote : <p className='ml-5'>-</p>}</div>
            )

        },
        {
            title: 'Early Punch Out Reason',
            dataIndex: 'punchoutnote',
            key: 'punchoutnote',
            render: punchoutnote => (
                <div>{punchoutnote ? punchoutnote : <p className='ml-5'>-</p>}</div>
            )

        },
        {
            title: 'Punchin Location',
            children: [
                {
                    title: 'Lat',
                    dataIndex: 'punchinlat',
                    key: 'punchinlat',
                    render: (punchinlat) => (
                        <>
                            {
                                punchinlat?.length > 10 ? punchinlat.substr(0, 10) + "..." : punchinlat
                            }
                        </>
                    )

                },
                {
                    title: 'Lon',
                    dataIndex: 'punchinlng',
                    key: 'punchinlng',
                    render: (punchinlng) => (
                        <>
                            {
                                punchinlng?.length > 10 ? punchinlng.substr(0, 10) + "..." : punchinlng
                            }
                        </>
                    )

                }, {
                    title: "Location",
                    key: 'Location',
                    render: (data) => (
                        <Flex justifyContent='center'>
                            {data.punchinlat ? <a href={`http://www.google.com/maps/place/${data.punchinlat},${data.punchinlng}`} target="_blank"><HiLocationMarker size={30} /></a> :
                                <p>-</p>
                            }
                        </Flex>
                    )
                }

            ]
        },
        {
            title: 'Punchout Location',
            children: [
                {
                    title: 'Lat',
                    dataIndex: 'punchoutlat',
                    key: 'punchoutlat',
                    render: (punchoutlat) => (
                        <>
                            {
                                punchoutlat?.length > 10 ? punchoutlat.substr(0, 10) + "..." : punchoutlat
                            }
                        </>
                    )

                },
                {
                    title: 'Lon',
                    dataIndex: 'punchoutlng',
                    key: 'punchoutlng',
                    render: (punchoutlng) => (
                        <>
                            {
                                punchoutlng?.length > 10 ? punchoutlng.substr(0, 10) + "..." : punchoutlng
                            }
                        </>
                    )

                },
                {
                    title: "Location",
                    key: 'Location',
                    render: (data) => (
                        <Flex justifyContent='center'>
                            {data.punchoutlat ? <a href={`http://www.google.com/maps/place/${data.punchoutlat},${data.punchoutlng}`} target="_blank"><HiLocationMarker size={30} /></a> :
                                <p>-</p>
                            }
                        </Flex>
                    )
                }

            ]
        },
        {
            title: 'Work Hour',
            dataIndex: 'workhour',
            key: 'workhour',

        }



    ]
    //custom time range
    const handleDateRange = (time) => {
        if (time) {
            const starttime = moment(time[0]).format("YYYY-MM-DD")
            const endtime = moment(time[1]).add(1, 'day').format("YYYY-MM-DD")
            setStartTime(starttime)
            setEndTime(endtime)
            setLoading(true)
            setPageno(1)

        } else {
            setStartTime('')
            setEndTime('')
            setLoading(true)

        }





    }
    //get Attendences
    const getAttendences = () => {
        setLoading(true)
        AppService.GetAttendenceByTimeRange(id, { startTime, endTime, pageno, itemsperpage }).then(resp => {

            setAttendences(resp.attendences)

            resp.data[0] && setTotalhr(resp.data[0].totalHours)
            setTotalAttendences(resp.count)
            setLoading(false)
        }

        ).catch(e => console.log(e))
    }
    //pagination
    const handlepageperitem = (current, size) => {

        setItemsperpage(size)
    }
    const handlepageno = (page) => {
        setPageno(page)
    }
    const handleDownload = () => {
        AppService.downloadAttendence({ userId: id, startTime, endTime }).then(resp => {
            window.open(`${API_BASE_URL}/${resp}`, "_blank");
        }).catch(e => console.log(e))
    }
    useEffect(() => {

        getAttendences()
    }, [pageno, startTime, endTime, itemsperpage])
    return (
        <>
            <Breadcrumb className='mb-3'>
                <Breadcrumb.Item>
                    <a href="/app/admin/user-list">List users</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Attendance
                </Breadcrumb.Item>

            </Breadcrumb>
            <div className="mt-3">

                <div >

                    <Card className='mt-3'>
                        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>

                            <DatePicker.RangePicker onChange={handleDateRange} />



                            <Flex alignItems="center"  >
                                <Button type="primary" onClick={handleDownload}>Export</Button>
                                <div className='ml-2' >
                                    Total work hours: <span>{totalhr?.toFixed()}</span>

                                </div>
                            </Flex>

                        </Flex>
                        <div className="table-responsive mt-3">
                            <Table bordered size="middle" rowKey={elem => elem._id} pagination={false} columns={columns} dataSource={attendences} loading={loading} pagination={false} />
                        </div>
                    </Card>

                    <Pagination className="mt-2" pageSizeOptions={['10', '20', '30', '40']} total={totalAttendences} current={pageno} pageSize={itemsperpage} onChange={handlepageno} showSizeChanger onShowSizeChange={handlepageperitem} showTotal={total => `Total ${total} items`} />
                </div>

            </div>
        </>
    )
}
