import { Divider, Drawer } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { API_BASE_URL } from 'configs/AppConfig'
import React from 'react'
import moment from 'moment-timezone'

export default function ViewUser(props) {
    const { data, visible, close } = props
    return (
        <Drawer
            width={300}
            placement="right"
            onClose={close}
            closable={false}
            visible={visible}
        >
            <div className="text-center mt-3">
                <Avatar size={80} src={`${API_BASE_URL}/images/${data?.profile}`} />
                <h3 className="mt-2 mb-0">{data?.name}</h3>
            </div>
            <Divider dashed />
            <p>
                
                <span className=" text-dark"><b>Employee Id:</b> {data?.empId}</span>
            </p>
            <p>
                
                <span className=" text-dark"><b>Email:</b> {data?.email}</span>
            </p>
            <p>
                
                <span className=" text-dark"><b>Phone no:</b> {data?.phoneno}</span>
            </p>
            <p>
                
                <span className=" text-dark"><b>Joining Date:</b> {data && moment(data.joiningDate).format("ddd MMM YYYY")}</span>
            </p>
            <p>
                
                <span className="text-capitalize text-dark"><b>Role:</b> {data?.role}</span>
            </p>


        </Drawer>
    )
}
