import React ,{Suspense} from 'react'
import Loading from 'components/shared-components/Loading';
import { Switch, Route, Redirect } from "react-router-dom";
import Timesheet from './Timesheet';
import AllTimesheets from './AllTimesheets';
import EditTimesheet from './EditTimesheet';


export default function TimesheetRoutes({match}) {
    return (
        
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route path={`${match.url}/timesheet/today-timesheet`}>
                    <Timesheet/> 
                </Route>
                <Route path={`${match.url}/timesheet/edit-timesheet/:id`}>
                    <EditTimesheet/> 
                </Route>
                <Route path={`${match.url}/timesheet/all`}>
                   <AllTimesheets/> 
                 </Route>
               
              
                <Redirect from={`${match.url}`} to={`${match.url}/timesheet/today-timesheet`} />
            </Switch>
        </Suspense>
    )
}
