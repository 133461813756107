import React ,{Suspense} from 'react'
import UserList from './UserList'
import Loading from 'components/shared-components/Loading';
import { Switch, Route, Redirect } from "react-router-dom";
import AddUser from './AddUser';
import EditUser from './EditUser';
import GetAttendence from './GetAttendence';
import Timesheets from './Timesheets';


export default function Admin({match}) {

    return (
        
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route path={`${match.url}/admin/user-list`}> 
                  <UserList/>
                </Route>
                <Route path={`${match.url}/admin/add-user`}> 
                  <AddUser/>
                </Route>
                <Route path={`${match.url}/admin/edit-user/:id`}> 
                  <EditUser/>
                </Route>
                <Route  path={`${match.url}/admin/timesheets/:id`}>
                  <Timesheets/>
                </Route>
                <Route path={`${match.url}/admin/attendance/:id`}> 
                  <GetAttendence/>
                </Route>
              
                <Redirect from={`${match.url}`} to={`${match.url}/admin/user-list`} />
            </Switch>
        </Suspense>
    )
}
