import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, DatePicker, Alert, InputNumber, Tag, message, TimePicker, notification } from 'antd';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import AppService from 'services/AppService';
import { motion } from "framer-motion"
import { showAuthMessage, showLoading, hideAuthMessage } from 'redux/actions/Auth';
import { useHistory } from 'react-router-dom';

 function AddUser(props) {
     const history=useHistory()
     const [form]= Form.useForm()
    const [filelist, setFilelist] = useState([])
    const { loading, message, showMessage,  showAuthMessage, showLoading, hideAuthMessage } = props
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3},
    };
    
    const validateMessages = {
        required: 'This field is required!',
        types: {
            email: 'Not a validate email!',
            number: 'Not a validate number!',
        },
        number: {
            range: 'Must be between ${min} and ${max}',
        },
    };
    const options= {
        // onRemove: file => {
        //     console.log(filelist)
        //     const index = filelist.indexOf(file)
        //     const newFileList = filelist.slice()
        //     newFileList.splice(index, 1)
        //     console.log(newFileList)
        //     setFilelist(newFileList)
        // },
        // previewFile:(file)=>{
        //    console.log(file)
        // },
        beforeUpload: async file => {

            // let src = await new Promise(resolve => {
            //     const reader = new FileReader();
            //     reader.readAsDataURL(file.originFileObj)
            //     reader.onload = () => resolve(reader.result);
            // })



            return false
        },
        onChange: async ({ fileList: newFileList }) => {
            // console.log(newFileList)
            const temp = newFileList.length - 1 >= 0 ? [newFileList[newFileList.length - 1]] : []

            let file = newFileList[newFileList.length - 1]
            // console.log(file)
            if (file) {
                let src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
                const image = new Image();

                image.src = src
                // console.log(image,src)
                // console.log(image.width,image.height)
                image.onload = function () {
                    // console.log(image.width, image.height)
                    if ((image.width / image.height) !== 1 ) {
                        showAuthMessage(
                            "Profile picture aspect ratio muste be 1:1 ."
                        )
                    } else {
                        setFilelist(temp);
                    }

                }


            } else {
                setFilelist(temp);
            }



        },
        onPreview: async file => {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();

            image.src = src;
            // console.log(image.width, image.height)
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        }
    }
    const handleFinish=(values)=>{
        
        var formData=new FormData()
        formData.append("email",values.email)
        formData.append("empId",values.empId)
        formData.append("image",values.files.file)
        formData.append("joiningDate",values.joiningDate)
        formData.append("name",values.name)
        formData.append("password",values.password)
        formData.append("phoneno",values.phoneno)
        formData.append("role",values.role)
        
        AppService.addUser(formData).then(resp=>{
            notification.success({
                message:"User created successfully!"
            })
           
            form.resetFields()
            setFilelist([])
            history.push('/app/admin/user-list')
        }).catch(e=>
            showAuthMessage(e.response.data)
        )
        
    }
    useEffect(() => {

        if (showMessage) {
            setTimeout(() => {
                hideAuthMessage();
            }, 3000);
        }
    });
    return (
        <>
        <h4>Add user</h4>

            <Form form={form} {...layout} onFinish={handleFinish} name="nest-messages" validateMessages={validateMessages}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="empId" label="Employee Id:" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="phoneno"
                    label="Phone Number"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                    <Select >
                        <Select.Option disabled >Select role</Select.Option>
                        <Select.Option value="admin">Admin</Select.Option>
                        <Select.Option value="user">User</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="joiningDate" label="Joining Date" rules={[{ required: true }]}>
                    <DatePicker />
                </Form.Item>
               
                <Form.Item
                    name="files"
                    label="Upload"
                    rules={[{ required: true }]}
                >

                    <Upload {...options} fileList={filelist} listType="picture-card" accept=".jpg,.png,.jpeg" >
                     
                            <UploadOutlined />
                   
                    </Upload>
                </Form.Item>





                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{
                        opacity: showMessage ? 1 : 0,
                        marginBottom: showMessage ? 20 : 0
                    }}>
                    <Alert type="error" showIcon message={message}></Alert>
                </motion.div>


                <Form.Item {...tailLayout}  >
                    <Button style={{width:'100px'}} type="primary" htmlType="submit" block loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
const mapStateToProps = ({ auth }) => {
   
    const { loading, message, showMessage, token } = auth;
    return { loading, message, showMessage, token }
}

const mapDispatchToProps = {
    showAuthMessage,
    showLoading,
    hideAuthMessage,

}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)
