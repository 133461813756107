import { Table, Button, notification, Pagination, Card,Modal, Tooltip  } from 'antd'
import React, { useEffect, useState } from 'react'
import { EyeOutlined, DeleteOutlined, EditOutlined, CalendarOutlined,ExclamationCircleOutlined, ProfileOutlined } from '@ant-design/icons';
import AppService from 'services/AppService'
import ViewUser from './ViewUser';
import { useHistory } from 'react-router-dom';

export default function UserList() {
    const history = useHistory()
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [userVisible, setuserVisible] = useState(false)
    const [pageno, setPageno] = useState(1)
    const [totalUsers, setTotalUsers] = useState(0)
    const [itemsperpage, setItemsperpage] = useState(10)

    const showUserDetails = (partner) => {
        setSelectedUser(partner)
        setuserVisible(true)

    }
    const closeUserDeatils = () => {
        setuserVisible(false)
        setSelectedUser(null)
    }
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Do you want to delete this user?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'Cancel',
            onOk() {
                AppService.deleteUser(id).then(resp => {
                    notification.success({
                        message: "User deleted successfully"
                    })
                    init()
                }).catch(e => {
                    notification.error({
                        message: 'Unable to delete user'
                    })
                })

            },

        })
        

    }
    const handleEdit = (id) => {
        history.push(`/app/admin/edit-user/${id}`)
    }
    const handleAttendence = (id) => {
        history.push(`/app/admin/attendance/${id}`)

    }
    const handleTimesheets = (id) => {
        history.push(`/app/admin/timesheets/${id}`)
    }
    const columns = [

        {
            title: 'Emp Id',
            dataIndex: 'empId',
            key: 'empId',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone no',
            dataIndex: 'phoneno',
            key: 'phonoeno',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render:(role)=><div  className='text-capitalize'>{role}</div>
        },
        {
            title: '',
            key:'x',
            render: (_, elm) => (
                <div key="x" className="text-right d-flex justify-content-end">
                     <Tooltip title ="Attendance"><Button className="mr-2" danger icon={<CalendarOutlined />} onClick={() => handleAttendence(elm._id)} size="small" /></Tooltip>
                     <Tooltip title=" Timesheets">
                     <Button className="mr-2" icon={<ProfileOutlined />} onClick={() => handleTimesheets(elm._id)} size="small" />
                     </Tooltip>
                     <Tooltip title="Edit user">
                     <Button className="mr-2" icon={<EditOutlined />} onClick={() => handleEdit(elm._id)} size="small" />
                     </Tooltip>
                     <Tooltip title="User details">
                     <Button type="primary" className="mr-2" icon={<EyeOutlined />} onClick={() => showUserDetails(elm)} size="small" />
                     </Tooltip>
                     <Tooltip title="Delete User">
                     <Button danger icon={<DeleteOutlined />} onClick={() => handleDelete(elm._id)} size="small" />
                     </Tooltip>




                    
                   
                    
                   


                </div>

            )


        }


    ]
    const handlepageperitem = (current, size) => {

        setItemsperpage(size)
    }
    const handlepageno = (page) => {
        setPageno(page)

    }

    const init = () => {
        AppService.getUsers().then(resp => {
            setTotalUsers(resp.length)
        }).catch(e => console.log(e))
        AppService.getPaginatedUsers({ pageno, itemsperpage }).then(resp => {

            setUsers(resp)
        }).catch(e => console.log(e))
    }
    useEffect(() => {
        init()
    }, [pageno, itemsperpage])
    return (
        <div>
            
            <Card >
                <Table pagination={false} rowKey={elem => elem._id} columns={columns} dataSource={users} />
            </Card>

            <Pagination className="mt-2" pageSizeOptions={['10', '20', '30', '40']} total={totalUsers} current={pageno} pageSize={itemsperpage} onChange={handlepageno} showSizeChanger onShowSizeChange={handlepageperitem} showTotal={total => `Total ${total} items`} />
            <ViewUser data={selectedUser} visible={userVisible} close={() => closeUserDeatils()} />
        </div>
    )
}
