import fetch from 'auth/FetchInterceptor'
import { method } from 'lodash'

const AppService = {}

//attendence 
AppService.getpunchin = function () {
	return fetch(
		{
			url: '/attendence/punch-in',
			method: 'get'
		}
	)
}
AppService.punchin = function (data) {
	return fetch({
		url: `/attendence/punch-in`,
		method: 'post',
		data
	})
}
AppService.punchout = function (data) {

	return fetch({
		url: `/attendence/punch-out`,
		method: 'post',
		data
	})
}
AppService.yesterdayPunchout = function (data) {
	
	return fetch({
		url: `/attendence/yesterday-punchout`,
		method: 'post',
		data
	})
}
AppService.GetAttendenceByTimeRange = function (data) {
	return fetch({
		url: `/attendence/time?startTime=${data.startTime}&endTime=${data.endTime}&pageno=${data.pageno}&itemsperpage=${data.itemsperpage}`,
		method: "get"
	})
}
AppService.getAllattendence = function (id) {
	return fetch({
		url: `/attendence/time/${id}`,
		method: "get"
	})
}
AppService.GetAttendenceByTimeRange = function (id,data) {
	return fetch({
		url: `/attendence/time?startTime=${data.startTime}&endTime=${data.endTime}&pageno=${data.pageno}&itemsperpage=${data.itemsperpage}&id=${id}`,
		method: "get"
	})
}
AppService.downloadAttendence=function(data){
	return fetch({
		url:`/attendence/download?startTime=${data.startTime}&endTime=${data.endTime}&userId=${data.userId}`,
		method: "get"
	})

}

//user
AppService.addUser = function (data) {
  
	return fetch({
		url: `/user`,
		method: "post",
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data
	})
}
AppService.getUsers = function () {
	return fetch({
		url: '/user',
		method: "get"
	})
}
AppService.getPaginatedUsers = function (data) {
	return fetch({
		url: `/user/paginate?pageno=${data.pageno}&itemsperpage=${data.itemsperpage}`,
		method: "get"
	})
}
AppService.getUser = function (id) {
	return fetch({
		url: `/user/${id}`,
		method: "get"
	})
}
AppService.resetpassword=function(data){
	return fetch({
		url:'/user/reset-password',
		method:'post',
		data
	})
}
AppService.editUser = function (id, data) {

	return fetch({
		url: `/user/${id}`,
		method: "patch",
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data
	})
}
AppService.deleteUser = function (id) {
	return fetch({
		url: `/user/${id}`,
		method: "delete"
	})
}
AppService.fetchProfile=function(){
	return fetch({
		url:`/user/profile`,
		method:"GET"
	})
}
//timesheet
AppService.getAlltimesheets=function(data){
	return fetch({
		url:`/timesheet?startTime=${data.startTime}&&endTime=${data.endTime}&&pageno=${data.pageno}&&itemsperpage=${data.itemsperpage}`,
		method:"get"
	})
}
AppService.getTimesheet=function(id){
	return fetch({
		url:`/timesheet/timesheet/${id}`,
		method:'get'
	})
}

AppService.updateTimesheetTask=function(id,data){
     return fetch({
		 url:`/timesheet/task/${id}`,
		 method:'put',
		 data	
	 })
	 
}

AppService.AddTimesheet=function(data){
	return fetch({
		url:"/timesheet",
		method:'post',
		data
	})

}

AppService.GetTimeSheetByDate=function(date){
	return fetch({
		url:`/timesheet/${date}`,
		method:"get"
	})
}
AppService.UpdateTimeSheet=function(id,data){
     return fetch({
		 url:`/timesheet/${id}`,
		 method:'put',
		 data
	 })
}
AppService.DoneForDay=function(id,data){
	return fetch({
		url:`/timesheet/done/${id}`,
		method:'put',
		data
	})
}
AppService.getUsersTimesheet=function(data){
	return fetch({
		url:`/timesheet/user?startTime=${data.startTime}&&endTime=${data.endTime}&&pageno=${data.pageno}&itemsperpage=${data.itemsperpage}&user=${data.user}`,
		method:'get'
	})
}
AppService.downloadTimesheet=function(data){
	return fetch({
		url:`/timesheet/download?userId=${data.userId}&&startTime=${data.startTime}&&endTime=${data.endTime}`,
		method:'get'
	})
}
export default AppService