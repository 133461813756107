import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import PunchInOut from './PunchInOut'
import AppService from 'services/AppService'
import { DatePicker, Pagination, Table, Card, Row, Col } from 'antd'
import Flex from 'components/shared-components/Flex'
import { connect } from 'react-redux'



function Attendence({ user }) {
    const [attendences, setAttendences] = useState([])
    const [totalAttendences, setTotalAttendences] = useState(0)
    const [loading, setLoading] = useState(false)
    const [totalhr, setTotalhr] = useState(0)
    const [pageno, setPageno] = useState(1)
    const [itemsperpage, setItemsperpage] = useState(10)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    //table
    const columns = [
        {
            title: 'Punchin',
            dataIndex: 'punchin',
            key: 'punchin',
            render: punchin =>
                <div>
                    {punchin ? <p> {moment(punchin).format("DD MMM YYYY")}, <span>{punchin && moment(punchin).format("hh:mm A")}</span></p> :
                        <div className='ml-5'> - </div>

                    }</div>

        },
        {
            title: 'Punchout',
            dataIndex: 'punchout',
            key: 'punchout',
            render: punchout =>
                <div>
                    {punchout ? <p> {moment(punchout).format("DD MMM YYYY")}, <span>{punchout && moment(punchout).format("hh:mm A")}</span></p> :
                        <div className='ml-5'> - </div>
                    }
                </div>

        },
        {
            title: 'Work Hour',
            dataIndex: 'workhour',
            key: 'workhour',

        }



    ]
    //custom time range
    const handleDateRange = (time) => {
        if (time) {
            const starttime = moment(time[0]).subtract('days',1)
            const endtime = moment(time[1])
            setStartTime(starttime)
            setEndTime(endtime)
            setLoading(true)
            setPageno(1)

        } else {
            setStartTime('')
            setEndTime('')
            setLoading(true)

        }
    }
    //get Attendences
    const getAttendences = () => {
        setLoading(true)
        
        AppService.GetAttendenceByTimeRange(user?.id, { startTime, endTime, pageno, itemsperpage }).then(resp => {
            setAttendences(resp.attendences)
            resp.data[0] && setTotalhr(resp.data[0].totalHours)
            setTotalAttendences(resp.count)
            setLoading(false)
        }

        ).catch(e => console.log(e))
    }
    //pagination
    const handlepageperitem = (current, size) => {

        setItemsperpage(size)
    }
    const handlepageno = (page) => {
        setPageno(page)
    }

    useEffect(() => {

        getAttendences()
    }, [pageno, startTime, endTime, itemsperpage])
    return (
        <>

            <PunchInOut getAttendences={getAttendences} />

            <div className="mt-3">
                <Card className='mt-3'>
                    <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
                        <Flex className="mb-1" mobileFlex={false}>
                            <DatePicker.RangePicker onChange={handleDateRange} />
                        </Flex>
                        <div  >
                            Total work hours: <span>{totalhr?.toFixed(2)}</span>

                        </div>
                    </Flex>
                    <div className="table-responsive">
                        <Table pagination={false} columns={columns} dataSource={attendences} loading={loading} pagination={false} />
                    </div>
                </Card>
                <Pagination className="mt-2" pageSizeOptions={[ '10', '20', '30', '40']} total={totalAttendences} current={pageno} pageSize={itemsperpage} onChange={handlepageno} showSizeChanger onShowSizeChange={handlepageperitem} showTotal={total => `Total ${total} items`} />
            </div>
        </>
    )
}
const mapStateToProps = ({ auth }) => {
    const { user } = auth;
    return { user }
}

export default connect(mapStateToProps)(Attendence)
