import React, { useEffect } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut,getProfile } from 'redux/actions/Auth';
import { API_BASE_URL } from "configs/AppConfig";
import Flex from "components/shared-components/Flex";

const menuItem = [
	{
		title: "Edit Profile",
		icon: EditOutlined ,
		path: "/"
    },
    
    {
		title: "Account Setting",
		icon: SettingOutlined,
		path: "/"
    },
    {
		title: "Billing",
		icon: ShopOutlined ,
		path: "/"
	},
    {
		title: "Help Center",
		icon: QuestionCircleOutlined,
		path: "/"
	}
]

 const NavProfile = ({signOut,user,getProfile,profile}) => {
 const profileImg = `${API_BASE_URL}/images/${profile?.profile}`;
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="p-2">
            <h4 className="mb-0">{profile?.name}</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  useEffect(()=>{
   getProfile()
  },[])
  return (
    <Flex alignItems="center">
    <h4 className="m-0">Welcome, <span className="text-capitalize">{profile?.name}</span></h4>
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
    </Flex>
  );
}
const mapStateToProps = ({ auth }) => {
  
	const { user,profile } = auth;

	return { user,profile }
}

export default connect(mapStateToProps,{signOut,getProfile})(NavProfile)

