import { Button, Form, Input, Modal, notification, TimePicker } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import AppService from 'services/AppService';

export default function EditTask({ show, data,fetchTimesheet, handleCancel,sheetId }) {
    const [form]= Form.useForm()
    form.setFieldsValue({...data,tasktime:[moment(data?.startTime),moment(data?.endTime)]})
    const onFinish=(values)=>{
        AppService.updateTimesheetTask(data._id,{...values,id:sheetId}).then(resp=>{
            notification.success({
                message:"Task updated successfully."
            })
            handleCancel()
            fetchTimesheet()
        }).catch(e=>notification.error({
            message: e?.response?.data ? e?.response?.data :"Unable to update task."
        }))
      
    }
    
    return (
        <Modal
            title="Update Task Details"
            visible={show}

            onCancel={handleCancel}
            footer={false}
        >
            <Form form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name="tasktime"
                    label="Time range"
                    rules={[{ required: true }]}

                >
                    <TimePicker.RangePicker  format="hh:mm A" />

                </Form.Item>


                {/* <Form.Item name="taskTags" label="Task Tags" rules={[{ required: true }]}>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Please select"


                            optionLabelProp="label"
                        >
                            {
                                tags.map(t=>
                                    <Option  value={t._id} label={t.name}   >
                                        {t.name}
                                    </Option>
                                    )
                            }


                        </Select>
                    </Form.Item> */}
                <Form.Item name="task" label="Task"  rules={[{ required: true }]} >
                    <Input.TextArea placeholder="Enter Task" allowClear />

                </Form.Item>
                <Form.Item name="project" label="Project"  rules={[{ required: true }]}>
                    <Input placeholder='Enter projet'></Input>
                </Form.Item>


                {/* <Form.Item name="project" label="Client/Project" rules={[{ required: true }]}>
                        <Select

                            labelInValue
                            style={{ width: 120 }}

                        >
                            {
                                projects.map((i) =>
                                    <Option value={`${i._id}`}>{i.name}</Option>
                                )
                            }

                        </Select>

                    </Form.Item> */}
                <Button style={{ width: '100px' }} type="primary" htmlType="submit" block>
                    Update
                </Button>


            </Form>

        </Modal>
    )
}
