import { Button, Modal, Form, TimePicker, Input, notification } from 'antd'



import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import AppService from 'services/AppService';



export default function PunchInOut({getAttendences}) {
    const [visible, setVisible] = useState(false)
    const [visible1, setVisible1] = useState(false)
    const [visible2, setVisible2]=useState(false)
    const [showpuchin, setShowpunchin] = useState(true)
    const [punchintime, setPunchintime] = useState(null)
    const [punchouttime, setPunchouttime] = useState(null)
    const [showpunchouttime,setShowpunchouttime]=useState(false)
    const [punchintemp, setPunchintemp] = useState(false)
    const [loading,setLoading]=useState(false)
    const [lat,setLat]=useState(null)
    const [lng,setLang]=useState(null)
    //modales close
    const handleCancel = () => {
        setVisible(false)
        setLoading(false)
    }
    const handleCancel1 = () => {
        setVisible1(false)
        setLoading(false)
    }
    const handleCancel2=()=>{
        setVisible2(false)
        setLoading(false)
    }

    //handle puch in and punch out
    const handlePunchIn = () => {
        navigator.geolocation.getCurrentPosition(success,error,options)

        const offsettime = moment(`${process.env.REACT_APP_PUNCHIN}`, 'h:mm A')
        const currenttime = moment()
        // console.log(currenttime.format("DD/MM/YYYY hh:mm a"),offsettime.format('DD/MM/YYYY hh:mm a'))
        setPunchintime(currenttime)

        
        
        setLoading(true)
       

        if (offsettime.isBefore(currenttime)) {
             
            setVisible(true)
        } else {

            AppService.punchin({ punchinlat:lat,punchinlng:lng }).then(
                (resp) =>{
                    notification.success({
                        message:"Punched in successfully!"
                    })
                    fetchtpunchin()
                    getAttendences()
                    setLoading(false)
                    }
            ).catch(e =>{
                notification.error({
                    message:e.response.data
                })
                setLoading(false)
            })
        }

        


    }
    const handlePunchOut = () => {
        navigator.geolocation.getCurrentPosition(success,error,options)
        setLoading(true)
        
        const punchindate = moment(punchintime).format("MM-DD-YYYY")


        const offsettime = moment(`${punchindate} ${process.env.REACT_APP_PUNCHOUT} `, 'MM-DD-YYYY hh:mm A')
        const currenttime = moment()
        setPunchouttime(currenttime)


        if (offsettime.isAfter(currenttime)) {
           
           setVisible1(true)
        } else {
            const punchindate = moment(punchintime).format("MM-DD-YYYY")
            const currentdate = moment().format("MM-DD-YYYY")
            // console.log(punchindate,currentdate)
            if(punchindate !== currentdate){
                 setVisible2(true)
                
            }else{
                AppService.punchout({ punchoutlat:lat,punchoutlng:lng }).then(resp => {
                    notification.success({
                        message:"Punched out successfully!"
                    })
                    setLoading(false)
                    fetchtpunchin()
                    getAttendences()
                }).catch(e => {
                    notification.error({
                        message:e.response.data
                    })
                    setLoading(false)
                }
                    )
            }
            
           


        }

    }
    //handle late and early punchout
    const handleFinish = (values) => {
        navigator.geolocation.getCurrentPosition(success,error,options)
        setVisible(false)
       const {punchin,...rest}=values
        AppService.punchin({ ...rest, punchinlat:lat,punchinlng:lng ,early:punchintemp,status:true}).then(
            (resp) => {
                notification.success({
                    message:"Punched in successfully!"
                })

                fetchtpunchin()
                setLoading(false)
                getAttendences()

            }

        ).catch(e => {
            notification.error({
                message:e.response.data
            })
            setLoading(false)})



    }
    const handleFinish1 = (values) => {
        navigator.geolocation.getCurrentPosition(success,error,options)
        setVisible1(false)
        AppService.punchout({ ...values ,punchoutlat:lat,punchoutlng:lng }).then(resp => {
           
            notification.success({
                message:"Punched out successfully!"
            })
            fetchtpunchin()
            
            setLoading(false)
            getAttendences()
        }).catch(e => {
            notification.error({
                message:e.response.data
            })
            setLoading(false)})





    }
    const handleFinish2=(values)=>{
        navigator.geolocation.getCurrentPosition(success,error,options)
        setVisible2(false)
       
        const punchindate = moment(punchintime).format("MM-DD-YYYY")
        const punchout=values.punchout.format("hh:mm A")
        const newPunchout=moment(`${punchindate} ${punchout}`)
      
          
        AppService.yesterdayPunchout({ punchout:newPunchout,punchoutnote:values.punchoutnote,punchoutlat:lat,punchoutlng:lng  }).then(resp => {
          
            notification.success({
                message:"Punched out successfully!"
            })
            fetchtpunchin()
            setLoading(false)
            getAttendences()
        }).catch(e =>
            {
                notification.error({
                    message:e.response.data
                })
                setLoading(false)})

    }
    const fetchtpunchin = () => {
        AppService.getpunchin().then((resp) => {
           
            setShowpunchin(!resp.punchin)
            if (resp.punchin) {
         
                setPunchintime(resp.punchintime)
            }
        }).catch(e => console.log(e))
    }
    const handleEarlychange = (e) => {
       setPunchintemp(e.target.checked)
   
    }
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      
      function success(pos) {
        var crd = pos.coords;
        setLat(crd.latitude)
        setLang(crd.longitude)
      }
      
      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }
      

    useEffect(() => {
        fetchtpunchin()
        navigator.geolocation.getCurrentPosition(success,error,options)
        
       
       
    }, [])



    return (
        <>
            {
                showpuchin ? <Button   loading={loading} onClick={handlePunchIn}>Punch In</Button> : <Button  loading={loading} onClick={handlePunchOut}> Punch Out</Button>

            }
            
            <Modal
                title="Late punch in "
                visible={visible}
                onCancel={handleCancel}
                footer={[

                ]}

            >
                <Form onFinish={handleFinish}>
                    <Form.Item name="punchinnote" label="Reason" rules={[{ required: true }]}>
                        <Input.TextArea maxLength={150}/>
                    </Form.Item>
               

                    <Button type="primary" htmlType="submit"  >Submit</Button>

                </Form>



            </Modal>
            <Modal
                title=" Early punch out  "
                visible={visible1}
                onCancel={handleCancel1}
                footer={[

                ]}

            >
                <Form onFinish={handleFinish1}>
                    <Form.Item name="punchoutnote" label="Reason"  rules={[{ required: true }]}>
                        <Input.TextArea maxLength={150} />
                    </Form.Item>
                    { showpunchouttime && <Form.Item name="punchout" label="Punchout time" rules={[{ required: true }]}>
                        <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>}
                    <Button type="primary" htmlType="submit"  >Submit</Button>

                </Form>



            </Modal>
            <Modal
                title=" Give punch out time "
                visible={visible2}
                onCancel={handleCancel2}
                footer={[

                ]}

            >
                 <Form onFinish={handleFinish2}>
                   
                     <Form.Item name="punchout" label="Punchout time" rules={[{ required: true }]}>
                        <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>
                    <Form.Item name="punchoutnote" label="Reason" >
                        <Input.TextArea maxLength={150} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit"  >Submit</Button>

                </Form>



            </Modal>
        </>
    )
}
