import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import Attendence from "./attendence";

import { connect } from "react-redux";
import Admin from "./admin";
import TimesheetRoutes from "./timesheet";
function RouteInterceptor({ children, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (user?.role === "admin") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `${APP_PREFIX_PATH}/attendance`,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const AppViews = ({ user, location, match }) => {

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <RouteInterceptor path={`${APP_PREFIX_PATH}/admin`} user={user} >
          <Admin location={location} match={match} />
        </RouteInterceptor>

        <Route path={`${APP_PREFIX_PATH}/attendance`}>
          <Attendence  />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/timesheet`}>
          <TimesheetRoutes location={location} match={match}/>
        </Route>
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/admin`} />
      </Switch>
    </Suspense>
  )
}

const mapStateToProps = ({ auth }) => {

  const { user } = auth;
  return { user }
};

export default withRouter(connect(mapStateToProps)(AppViews));