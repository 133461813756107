import { Table, Card, Breadcrumb, Pagination, DatePicker, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppService from 'services/AppService';
import { EyeOutlined } from '@ant-design/icons';
import ViewTimesheet from './ViewTimesheet';
import moment from 'moment'
import Flex from 'components/shared-components/Flex';
export default function Timesheets() {
    const { id } = useParams()
    const [pageno, setPageno] = useState(1)
    const [itemsperpage, setItemsperpage] = useState(10)
    const [timesheets, setTimesheets] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [show, setShow] = useState(false)
    const [totalItems, setTotalItems] = useState(0)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")

    const viewItem = (elm) => {
        setSelectedItem(elm)
        setShow(true)
    }
    const hideItem = () => {
        setSelectedItem(null)
        setShow(false)
    }
    const columns = [{

        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date) => (
            <>
                {
                    moment(date).format("DD-MM-YYYY")
                }
            </>
        )



    },

    {

        title: 'Total Hours',
        key: 'hours',
        render: (elem) => (
            <>
                {
                    elem?.totalHours ? elem?.totalHours : <span className='ml-4'>-</span>
                }
            </>
        )



    },
    {
        title: "Punchin",
        key: 'punchin',
        render: (elem) => (
            <>
                {
                    elem?.attendence?.punchin ? moment(elem.attendence.punchin).format("hh:mm a") : " - "
                }
            </>
        )
    },
    {
        title: "Punchout",
        key: 'punchout',
        render: (elem) => (
            <>
                {
                    elem?.attendence?.punchout ? moment(elem.attendence.punchout).format("hh:mm a") : " - "
                }
            </>
        )
    },
    {
        title: "Actions",
        render: (_, elm) => (


            <div key={elm._id} style={{ width: '50px' }} className="d-flex justify-content-between">
                <EyeOutlined onClick={() => viewItem(elm)} style={{ color: "#2462B3" }} />


            </div>

        )
    }
    ]
    const handlepageperitem = (current, size) => {

        setItemsperpage(size)
    }
    const handlepageno = (page) => {
        setPageno(page)

    }
    const handleDateRange = (time) => {
        if (time) {
            const starttime = moment(time[0]).subtract('days', 1)
            const endtime = moment(time[1])
            setStartTime(starttime)
            setEndTime(endtime)
            // setLoading(true)
            setPageno(1)

        } else {
            setStartTime('')
            setEndTime('')
            // setLoading(true)

        }





    }
    const downloadTimesheet = () => {
        AppService.downloadTimesheet({ userId: id, startTime, endTime }).then(resp => console.log(resp)).catch(e => console.log(e))
    }
    useEffect(() => {
        AppService.getUsersTimesheet({ user: id, pageno, itemsperpage, startTime, endTime }).then(resp => {
            // console.log(resp)
            if (resp) {
                setTimesheets(resp.timesheets)
                setTotalItems(resp.total)

            }
        }).catch(e => console.log(e))
    }, [pageno, itemsperpage, startTime, endTime])
    return (
        <>
            <Breadcrumb className='mb-3'>
                <Breadcrumb.Item>
                    <a href="/app/admin/user-list">List users</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Timesheets
                </Breadcrumb.Item>

            </Breadcrumb>

            <Card title="Timesheets">

                <DatePicker.RangePicker onChange={handleDateRange} />



                <Table
                    className='mt-3'
                    bordered
                    dataSource={timesheets}
                    columns={columns}
                    rowKey={(data) => data._id}
                    pagination={false} />
                <Pagination className="mt-2" pageSizeOptions={['10', '20', '30', '40']} total={totalItems} current={pageno} pageSize={itemsperpage} onChange={handlepageno} showSizeChanger onShowSizeChange={handlepageperitem} showTotal={total => `Total ${total} items`} />
                <ViewTimesheet show={show} handleCancel={hideItem} data={selectedItem} />
            </Card>
        </>

    );
}
