import React, { useState } from 'react'
import { Form, Input, Button, Select, DatePicker,Card, Alert, Modal,Breadcrumb, InputNumber, Tag, message, TimePicker, notification } from 'antd';
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AppService from 'services/AppService'
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { motion } from "framer-motion";
import moment from 'moment';
import { showAuthMessage, showLoading, hideAuthMessage,getProfile } from 'redux/actions/Auth';
import { API_BASE_URL } from 'configs/AppConfig';

function EditUser(props) {
    const [form] = Form.useForm()
    const { loading, message, showMessage, showAuthMessage, showLoading, hideAuthMessage,getProfile } = props
    const [filelist, setFilelist] = useState([])
    const [visible, setVisible] = useState(false)
    const { id } = useParams()
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };



    const validateMessages = {
        required: 'This field is required!',
        types: {
            email: 'Not a validate email!',
            number: 'Not a validate number!',
        },
        number: {
            range: 'Must be between ${min} and ${max}',
        },
    };
    const handleFinish = (values) => {
        var formData = new FormData()
        formData.append("email", values.email)
        formData.append("empId", values.empId)
        formData.append("image", values.files?.file)
        formData.append("joiningDate", values.joiningDate)
        formData.append("name", values.name)
        formData.append("phoneno", values.phoneno)
        formData.append("role", values.role)
        AppService.editUser(id, formData).then(resp => {
            notification.success({
                message: "User profile updated successfully"
            })
            getProfile()
        }).catch(e => {
            showAuthMessage(e.response.data)
        })

    }
    const handlePassword = (values) => {
        const { password } = values
        const data = { password, id }

        AppService.resetpassword(data).then(resp => {
            notification.success({
                message: "Member password reset successfully"
            })
            form.resetFields()
        }).catch(err => {

            if (err.response) {


                err.response.data.errors.forEach(e =>
                    showAuthMessage(err.msg)
                )
            }
        })
    }
    const options= {
        // onRemove: file => {
        //     console.log(filelist)
        //     const index = filelist.indexOf(file)
        //     const newFileList = filelist.slice()
        //     newFileList.splice(index, 1)
        //     console.log(newFileList)
        //     setFilelist(newFileList)
        // },
        // previewFile:(file)=>{
        //    console.log(file)
        // },
        beforeUpload: async file => {

            // let src = await new Promise(resolve => {
            //     const reader = new FileReader();
            //     reader.readAsDataURL(file.originFileObj)
            //     reader.onload = () => resolve(reader.result);
            // })



            return false
        },
        onChange: async ({ fileList: newFileList }) => {
            // console.log(newFileList)
            const temp = newFileList.length - 1 >= 0 ? [newFileList[newFileList.length - 1]] : []

            let file = newFileList[newFileList.length - 1]
            // console.log(file)
            if (file) {
                let src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
                const image = new Image();

                image.src = src
                // console.log(image,src)
                // console.log(image.width,image.height)
                image.onload = function () {
                    // console.log(image.width, image.height)
                    if ((image.width / image.height) !== 1 ) {
                        showAuthMessage(
                            "Profile picture aspect ratio muste be 1:1 ."
                        )
                    } else {
                        setFilelist(temp);
                    }

                }


            } else {
                setFilelist(temp);
            }



        },
        onPreview: async file => {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();

            image.src = src;
            // console.log(image.width, image.height)
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        }
    }
    useEffect(() => {

        AppService.getUser(id).then(resp => {

            const { joiningDate, ...rest } = resp
            form.setFieldsValue({ ...rest, joiningDate: moment(joiningDate) })
            resp.profile && setFilelist([
                {
                    uid: '-1',
                    name: `${resp.profile}`,
                    status: 'done',
                    url: `${API_BASE_URL}/images/${resp.profile}`,
                }
            ])
        }).catch(e => {
            // console.log(e)
        })
    }, [])
    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                hideAuthMessage();
            }, 3000);
        }
    })

    const showModal = () => {
        setVisible(true)
    };


    const handleCancel = e => {
        setVisible(false)

    };
    return (
        <>
            <Breadcrumb className='mb-3'>
                <Breadcrumb.Item>
                    <a href="/app/admin/user-list">List users</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                   Update user
                </Breadcrumb.Item>

            </Breadcrumb>
            <Card title="Update user">
            <Form {...layout} form={form} onFinish={handleFinish} name="nest-messages" validateMessages={validateMessages}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="empId" label="Employee Id:" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ type: 'email', required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phoneno"
                    label="Phone Number"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                    <Select >
                        <Select.Option disabled >Select role</Select.Option>
                        <Select.Option value="admin">Admin</Select.Option>
                        <Select.Option value="user">User</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="joiningDate" label="Joining Date" rules={[{ required: true }]}>
                    <DatePicker />
                </Form.Item>

                <Form.Item
                    name="files"
                    label="Upload"
                >

                    <Upload {...options} fileList={filelist} listType="picture-card" accept=".jpg,.png,.jpeg" >
                     
                            <UploadOutlined />
                   
                    </Upload>
                </Form.Item>



                <motion.div
                    initial={{ opacity: 0, marginBottom: 0 }}
                    animate={{
                        opacity: showMessage ? 1 : 0,
                        marginBottom: showMessage ? 20 : 0
                    }}>
                    <Alert type="error" showIcon message={message}></Alert>
                </motion.div>


                <Form.Item {...tailLayout}>


                    <Button style={{ width: '100px' }} type="primary" htmlType="submit" block loading={loading}>
                        Update
                    </Button>
                    <Button type="danger" className='ml-2' onClick={showModal}>
                        Reset password
                    </Button>


                </Form.Item>
            </Form>

            </Card>

           
            <div >

                <Modal
                    title="Password Reset Form"
                    visible={visible}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>
                    ]}

                    onCancel={handleCancel}
                >
                    <Form onFinish={handlePassword}>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item  >
                            <Button style={{ width: '100px' }} type="primary" htmlType="submit" block >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                </Modal>
            </div>

        </>

    )
}
const mapStateToProps = ({ auth }) => {

    const { loading, message, showMessage, token,profile } = auth;
    return { loading, message, showMessage, token,profile }
}

const mapDispatchToProps = {
    showAuthMessage,
    showLoading,
    hideAuthMessage,
  getProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)