import { Col, Divider, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import moment from 'moment-timezone'

export default function ViewTimesheet({ show, data, handleCancel }) {
    return <>
        <Modal
            title={`Timesheet ${moment(data?.date).format("DD/MM/YYYY")}`}
            visible={show}

            onCancel={handleCancel}
            footer={false}
        >
            <div>
                <p><span><b>Date: </b></span> {moment(data?.date).format("DD/MM/YYYY")}</p>
                <p><span><b>Total hours: </b></span> {data?.totalHours}</p>
                {
                    data?.task?.map(item => <>
                        <Divider />
                        <Row>
                            <Col md={8}>

                                <p><span><b>Start Time: </b></span> {moment(item?.startTime).format("hh:mm a")}</p>
                            </Col>
                            <Col md={8}>
                                <p><span><b>End Time: </b></span> {moment(item?.endTime).format("hh:mm a")}</p>

                            </Col>
                            <Col>
                                <p><span><b>Hours: </b></span> {item?.hours}</p>
                            </Col>
                            <Col md={24}>
                                <p><span><b>Task: </b></span> {item?.task}</p>
                            </Col>
                            <Col md={24}>
                                <p><span><b>Project: </b></span> {item?.task}</p>
                            </Col>
                        </Row>
                    </>)
                }

            </div>
        </Modal>
    </>;
}
