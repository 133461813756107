import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { setProfile, showAuthMessage } from 'redux/actions/Auth';

import { GETUSER } from 'redux/constants/Auth';
import AppService from 'services/AppService';






export function* getProfile() {
  
    yield takeEvery(GETUSER, function* () {
        
        try {
            const user = yield call(AppService.fetchProfile)
           
            yield put(setProfile(user))
        } catch (err) {
            yield put(showAuthMessage(err));
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(getProfile)
    ])
}