
import { Form, Select, Input, Button, TimePicker, Modal, Table, Card, Checkbox, notification, Breadcrumb } from 'antd'
import App from 'App';
import Flex from 'components/shared-components/Flex';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import AppService from 'services/AppService';
import { PlusCircleOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import EditTask from './component/EditTask';



export default function Timesheet() {
    const { id } = useParams()
    const history = useHistory()
    let today = moment().format("DD-MM-YYYY")
    const [form] = Form.useForm()
    const [sheetId, setSheetId] = useState(null)
    const [task, setTask] = useState([])
    const [visible, setVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [show, setShow] = useState(false)
    const [totalhr, setTotalhr] = useState(null)
    const [doneForDay, setDoneForDay] = useState(true)
    const [timehsheet,setTimesheet]=useState(null)
    const EditTimesheet = (elm) => {
        setShow(true)
        setSelectedItem(elm)
    }
    const onCancel = () => {
        setSelectedItem(null)
        setShow(false)
    }
    const columns = [{

        title: 'Start time',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (data) => (
            <div key={data}>
                {
                    moment(data).format('hh:mm a')
                }
            </div>
        )



    },
    {

        title: 'End time',
        dataIndex: 'endTime',
        key: 'endTime',
        render: (data) => (
            <div key={data}>
                {
                    moment(data).format('hh:mm a')
                }
            </div>
        )




    },
    {

        title: 'Project',
        dataIndex: 'project',
        key: 'project',



    },
    {

        title: 'Task',
        dataIndex: 'task',
        key: 'task',
        render: (task) => (
            <>
                {
                    task.length < 20 ? <p className='m-0'>{task}</p> : <p className='m-0'>{task.substr(0, 20) + '....'}</p>
                }
            </>
        )



    },
    {

        title: 'Hours',
        dataIndex: 'hours',
        key: 'hours',



    },

    {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, elm) => (


            <div key={elm._id} style={{ width: '50px' }} className="d-flex justify-content-between">
                {doneForDay ? <CheckOutlined style={{ color: 'green', fontSize: "20px" }} /> : <EditOutlined onClick={() => EditTimesheet(elm)} style={{ color: "#2462B3" }} />}


            </div>

        )
    },
    ]
    const onFinish = (values) => {
        getdisabledHours()


 
            AppService.UpdateTimeSheet(sheetId, values).then(resp => {
            
                fetchTimesheet()
                form.resetFields()
                setVisible(false)
            }).catch(e => notification.error({
                message:e?.response?.data?e?.response?.data:'Problem while adding task.'
               }))


          
        


    }
    const getdisabledHours = () => {
        var tempArr = []

        task.forEach(t => {
            let tempVal
            let i = 0

            let endTime = parseInt(moment(t.endTime).format('HH'))




            while (tempVal !== endTime - 1) {
                const val = parseInt(moment(t.startTime).add(i, 'hours').format("HH"))
                tempVal = val
                tempArr.push(val)

                i++


            }






        })

        return tempArr





    }
    const fetchTimesheet = () => {
        AppService.getTimesheet(id).then(resp => {
            if (resp) {
                // console.log(resp)
                setTask(resp.timesheet.task)
                setTotalhr(resp.totalHours)
                setDoneForDay(resp.timesheet.done)
                setSheetId(resp.timesheet._id)
                setTimesheet(resp.timesheet)

            }
        }).catch(e => console.log(e))

    }
    const handleDone = (values) => {
        AppService.DoneForDay(sheetId, values).then(resp => {
            notification.success({
                message: 'Thanks to submit timesheet.'
            })
            fetchTimesheet()
        }).catch(e => {
            notification.error({
                message:  "Unable to submit timesheet."
            })
        })
    }
    const handleCancel = () => {
        setVisible(false)
    }
    useEffect(() => {
        fetchTimesheet()



    }, [])
    return (
        <>

                <Breadcrumb className='mb-3' separator=">">
                    <Breadcrumb.Item href='/app/timesheet'>Timesheet</Breadcrumb.Item>
                    <Breadcrumb.Item href='/app/timesheet/all'>AllTimesheets</Breadcrumb.Item>
                    <Breadcrumb.Item >Edit Timesheet</Breadcrumb.Item>
                </Breadcrumb>
               
      




            {/* <div style={{ width: "500px", marginTop: "10px" }}>
                <h4>My time sheet for {today}</h4>
                

                {
                    task.map(t => (
                        <div style={{ border: "1px solid gray", borderRadius: "20px", padding: "10px", marginTop: "10px" }}>
                            <p> <b>Start Time:</b> <span>{moment(t.startTime).format("hh:mm A")}</span></p>
                            <p><b>Project</b> <span>{t?.project}</span></p>
                            <p><b>task:</b> <span>{t?.task}</span></p>

                            <p><b>End Time:</b> <span>{moment(t.endTime).format("hh:mm A")}</span></p>

                        </div>
                    ))
                }

            </div> */}
            <Card className='mt-3' >
                <Flex className='mb-4' justifyContent="between">
                    <h3>{`My timesheet for ${moment(timehsheet?.date).format('DD-MM-YYYY')} `}</h3>
                    {
                        !doneForDay ? <Button type="primary" onClick={() => { setVisible(true) }}> <PlusCircleOutlined />Add Task</Button> : ''
                    }

                </Flex>
                <Table
                    dataSource={task}
                    expandable={{
                        expandedRowRender: data => <p style={{ margin: 0 }}><span>Task: </span>{data.task}</p>,
                        rowExpandable: data => data.task.length > 20
                    }}
                    columns={columns}
                    rowKey={(data) => data._id}
                    pagination={false} />

                {
                    !doneForDay &&  sheetId ? <>
                        <Form className='mt-3' layout='inline' onFinish={handleDone} >
                            <Form.Item name="done" label="Done for day" valuePropName="checked" 
                            rules={[{ required: true,
                            message:"Provide done for day"
                            }]} 
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block >
                                    Submit
                                </Button>
                            </Form.Item>

                        </Form>
                    </> : ''
                }

                <Flex className='mt-3' justifyContent='end'>
                    <p><span>Total Hours: </span> {totalhr}</p>
                </Flex>

            </Card>


            <Modal
                title="Add Task"
                visible={visible}
                onCancel={handleCancel}
                footer={false}
            >
                <Form form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="tasktime"
                        label="Time range"
                        rules={[{ required: true }]}

                    >
                        <TimePicker.RangePicker format="hh:mm A" />

                    </Form.Item>


                    {/* <Form.Item name="taskTags" label="Task Tags" rules={[{ required: true }]}>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Please select"


                            optionLabelProp="label"
                        >
                            {
                                tags.map(t=>
                                    <Option  value={t._id} label={t.name}   >
                                        {t.name}
                                    </Option>
                                    )
                            }


                        </Select>
                    </Form.Item> */}
                    <Form.Item name="task" label="Task" rules={[{ required: true }]} >
                        <Input.TextArea placeholder="Enter Task" allowClear />

                    </Form.Item>
                    <Form.Item name="project" label="Project" rules={[{ required: true }]}>
                        <Input placeholder='Enter projet'></Input>
                    </Form.Item>


                    {/* <Form.Item name="project" label="Client/Project" rules={[{ required: true }]}>
                        <Select

                            labelInValue
                            style={{ width: 120 }}

                        >
                            {
                                projects.map((i) =>
                                    <Option value={`${i._id}`}>{i.name}</Option>
                                )
                            }

                        </Select>

                    </Form.Item> */}
                    <Button style={{ width: '100px' }} type="primary" htmlType="submit" block>
                        Add
                    </Button>


                </Form>
            </Modal>
            <EditTask data={selectedItem} fetchTimesheet={fetchTimesheet} sheetId={sheetId} handleCancel={onCancel} show={show} />
        </>

    )
}
