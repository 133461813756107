import { Breadcrumb, Card, Pagination, Table,DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import AppService from 'services/AppService'
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import moment from 'moment-timezone';
export default function AllTimesheets() {
    const history = useHistory()
    const [timesheets, setTimesheets] = useState([])
    const [sheetId, setSheetId] = useState(null)
    const [task, setTask] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [pageno, setPageno] = useState(1)
    const [itemsperpage, setItemsperpage] = useState(10)
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")

    const viewTimesheet = (id) => {
        history.push(`/app/timesheet/edit-timesheet/${id}`)
    }
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render:(date)=>(<>
            {
                moment(date).format("DD-MM-YYYY")
            }
            </>)
        },
        {
            title:"Punchin",
            key:'punchin',
            render:(elem)=>(
                <>
                {
                     elem?.attendence?.punchin ? moment(elem.attendence.punchin).format("hh:mm a"):" - "
                }
                </>
            )
        },
        {
            title:"Punchout",
            key:'punchout',
            render:(elem)=>(
                <>
                {
                      elem?.attendence?.punchout ? moment(elem.attendence.punchout).format("hh:mm a"):" - "
                }
                </>
            )
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, elm) => (
                // <div className="text-right">
                //     <EllipsisDropdown menu={dropdownMenu(elm)}/>
                // </div>

                <div style={{ width: '50px' }} className="d-flex justify-content-between">
                    <EditOutlined onClick={() => viewTimesheet(elm._id)} style={{ color: "#2462B3" }} />


                </div>

            )
        },

    ]
    const handlepageperitem = (current, size) => {

        setItemsperpage(size)
    }
    const handlepageno = (page) => {
        setPageno(page)
    }
    const handleDateRange = (time) => {
        if (time) {
            const starttime = moment(time[0]).subtract('days',1)
            const endtime = moment(time[1])
            setStartTime(starttime)
            setEndTime(endtime)
            // setLoading(true)
            setPageno(1)

        } else {
            setStartTime('')
            setEndTime('')
            // setLoading(true)

        }





    }
    useEffect(() => {
        AppService.getAlltimesheets({ startTime, endTime, pageno, itemsperpage }).then(resp => {
           
            setTimesheets(resp.timesheets)
            setTotalItems(resp.total)
        }).catch(e => console.log(e))
    }, [startTime,  pageno, itemsperpage])
    return (
        <>
            <Breadcrumb className='mb-3' separator=">">
                <Breadcrumb.Item href='/app/timesheet'>Timesheet</Breadcrumb.Item>
                <Breadcrumb.Item >AllTimesheets</Breadcrumb.Item>
            </Breadcrumb>
            <Card title="All Timesheets">
            <DatePicker.RangePicker onChange={handleDateRange} />
                <Table columns={columns} rowKey={elm=> elm._id} dataSource={timesheets} pagination={false}></Table>
            </Card>
            <Pagination className="mt-2" pageSizeOptions={['10', '20', '30', '40']} total={totalItems} current={pageno} pageSize={itemsperpage} onChange={handlepageno} showSizeChanger onShowSizeChange={handlepageperitem} showTotal={total => `Total ${total} items`} />
        </>



    )
}
