import {
  CalendarOutlined,
  UserOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'attendence',
  path: `${APP_PREFIX_PATH}/attendance`,
  title: 'sidenav.apps.user.attendance' ,
  icon: CalendarOutlined,
  role: ['user'],
  breadcrumb: false,
  submenu: []
},
{
  key: 'timesheet',
  path: `${APP_PREFIX_PATH}/timesheet`,
  title: 'sidenav.apps.user.timesheet' ,
  icon: CalendarOutlined,
  role: ['user'],
  breadcrumb: false,
  submenu: []
},
{
  key: 'user',
  path: `${APP_PREFIX_PATH}/admin`,
  title: 'sidenav.apps.user.user',
  role: ['admin'],
  icon: UserOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-project-list',
      path: `${APP_PREFIX_PATH}/admin/user-list`,
      title: 'sidenav.apps.user.listuser',
      icon: UserOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-project-adduser',
      path: `${APP_PREFIX_PATH}/admin/add-user`,
      title: 'sidenav.apps.user.adduser',
      icon: UserAddOutlined,
      breadcrumb: false,
      submenu: []
    }

  ]
}

]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
